
import BimCompareContainer from "@/components/bimCompare/BimCompareContainer"
import { mapStores } from "pinia"
import { useCameraStore } from "@/stores/camera"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
  InfoPage,
} from "@evercam/shared/types"

export default {
  meta: {
    pageId: AnalyticsEventPageId.BimCompare,
  },
  components: {
    BimCompareContainer,
  },
  middleware: ["ensureCameraRoute"],
  async asyncData({ redirect, $permissions }) {
    const cameraStore = useCameraStore()
    if (!$permissions.user.can.access.bim() || !$permissions.camera.has.bim()) {
      redirect(`${cameraStore.cameraRoute}/info/${InfoPage.BimCompare}`)
    }
  },
  head() {
    return {
      title: `${this.cameraStore.selectedCamera?.name || "Evercam"} | ${this.$t(
        "pages.bim_compare"
      )}`,
      meta: [
        { charset: "utf-8" },
        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useCameraStore),
  },
  mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.PageView)
  },
}
